<template>
  <div class="page">
    <ls-title-header @back="cancel" title="新增管理"></ls-title-header>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
      <div style="background-color: white;">
      <div class="box">
      <el-row class="row">
        <el-col :span="24">
          <el-form-item prop="name">
            <template #label><span class="title">姓&emsp;&emsp;名</span></template>
            <el-input v-model="ruleForm.name" placeholder="请输入姓名" maxlength="15" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
  
      <el-row class="row">
        <el-col :span="24">
          <el-form-item prop="idcard">
            <template #label><span class="title">身&ensp;份&ensp;证</span></template>
            <el-input v-model="ruleForm.idcard" placeholder="请输入身份证" @input="idcardChange" maxlength="18" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item label="年龄">
            <template #label><span class="title">年&emsp;&emsp;龄</span></template>
            <el-input v-model="ruleForm.age" placeholder="请先输入身份证" disabled class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">出生年月</span></template>
            <el-input v-model="ruleForm.birthday" placeholder="请先输入身份证" disabled class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

        <!-- <el-form-item label="民族" prop="type" class="title">
          <el-input v-model="ruleForm.name" placeholder="请输入民族"></el-input>
        </el-form-item> -->
      <el-row class="row">
        <el-col :span="24">
          <el-form-item prop="phone">
            <template #label><span class="title">手机号码</span></template>
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" maxlength="11" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">居住地址</span></template>
            <ls-address style="display: flex;justify-content: space-between;width:100%;" @adr-change="getAddress"></ls-address>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <el-input v-model="this.address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">信息备注</span></template>
            <el-input v-model="ruleForm.remarks" placeholder="请输入信息备注" maxlength="150" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">设备预警</span></template>
            <el-switch @change="deviceChange" style="display: block;margin-top: 10px;width:150px;" v-model="warningType.deviceSwitch" active-color="#13ce66" active-text="开启" inactive-text="关闭"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">健康预警</span></template>
            <el-switch @change="healthChange" style="display: block;margin-top: 10px;width:150px;" v-model="warningType.healthSwitch" active-color="#13ce66" active-text="开启" inactive-text="关闭"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <template #label><span class="title">预警号码</span></template>
            <el-input v-model="ruleForm.warningPhone" :disabled=isDisabled placeholder="请输入手机号码" maxlength="11" show-word-limit class="input"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
              <template #label><span class="title">通知信息</span></template>
              <div style="display:flex;align-items: left;">
                  <el-checkbox label="电话通知" v-model="warningType.voiceSwitch" :disabled=isDisabled></el-checkbox>
                  <el-checkbox label="短信通知" v-model="warningType.smsSwitch" :disabled=isDisabled></el-checkbox>
                  <el-checkbox label="公众号通知" v-model="warningType.accountSwitch" :disabled=isDisabled></el-checkbox>
              </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="24">
          <el-form-item>
            <!-- <el-button type="primary" @click="cancel" size="small" plain>返回</el-button> -->
            <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      </div>
      </div>
    </el-form>
  </div>

</template>

<script>
import {addManagerInfo} from '../../../api/api'
import session from '../../../store/store'
import idtiother from '../../../until/idcard'
import util from "../../../until/until";

export default {
  data() {
    return {
      ruleForm: {
        name: '',
        idcard:'',
        birthday:'',
        age:'',
        phone: '',
        remarks:'',
        address:'',
        warningPhone:'',
        deviceSwitch:0,
        healthSwitch:0,
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      warningType:{
        deviceSwitch:false,
        healthSwitch:false,
        smsSwitch:false,
        voiceSwitch:false,
        accountSwitch:false,
      },
      isDisabled:true,
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 1, max: 9, message: "长度在 1 到 9 个字符", trigger: "blur"},
        ],
        idcard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        phone:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 11 个数字", trigger: "blur"},
          {pattern: /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/, message: "请输入合法手机号", trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
     /**
     * 身份证输入框改变时触发
     */
    idcardChange(v) {
      let idList = idtiother.go(v);
      this.ruleForm.birthday = idList.birth;
      this.ruleForm.age = idList.age;
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.addManager();
        } else {
          return false;
        }
      });
    },

    addManager() {
      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.ruleForm.address = '';
      }else {
        this.ruleForm.address = address;
      }

      if(this.warningType.smsSwitch) {
        this.ruleForm.smsSwitch = 1;
      }else {
        this.ruleForm.smsSwitch = 0;
      }

      if(this.warningType.voiceSwitch) {
        this.ruleForm.voiceSwitch = 1;
      }else {
        this.ruleForm.voiceSwitch = 0;
      }

      if(this.warningType.accountSwitch) {
        this.ruleForm.accountSwitch = 1;
      }else {
        this.ruleForm.accountSwitch = 0;
      }

      if(this.ruleForm.deviceSwitch == 1 || this.ruleForm.healthSwitch == 1) {
        if(util.isNULL(this.ruleForm.warningPhone)) {
          this.$message.error({ message: "请先设置预警电话"});
          return
        } 
      }

      var param = {
        uuid: session.getLoginInfo().unitUuid,
        name: this.ruleForm.name,
        address:this.ruleForm.address,
        idcard:this.ruleForm.idcard,
        phone:this.ruleForm.phone,
        remarks:this.ruleForm.remarks,
        smsSwitch:this.ruleForm.smsSwitch,
        voiceSwitch:this.ruleForm.voiceSwitch,
        accountSwitch:this.ruleForm.accountSwitch,
        deviceSwitch:this.ruleForm.deviceSwitch,
        healthSwitch:this.ruleForm.healthSwitch,
        warningPhone:this.ruleForm.warningPhone
      }
      addManagerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '新增成功'});
          this.cancel();
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    deviceChange(value) {
      if(value) {
        this.isDisabled = false
        this.ruleForm.deviceSwitch = 1
      }else {
        this.ruleForm.deviceSwitch = 0
      }

      if(this.ruleForm.deviceSwitch == 0 && this.ruleForm.healthSwitch == 0) {
        this.isDisabled = true
      }
    },
    healthChange(value) {
      if(value) {
        this.isDisabled = false
        this.ruleForm.healthSwitch = 1
      }else {
        this.ruleForm.healthSwitch = 0
      }
      if(this.ruleForm.deviceSwitch == 0 && this.ruleForm.healthSwitch == 0) {
        this.isDisabled = true
      }
    },

    cancel(){
      this.$emit("cancel")
    },

    close(){
      this.$emit("update")
    }
  }
}
</script>

<style scoped>

.page {
  padding: 0px 20px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}

.input {
  width:100%;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -20px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>