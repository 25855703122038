export default {
    // 实现自动生成生日，性别，年龄
    go(val) {
        
        let iden = val;
        let sex = null;
        let birth = null;
        let myDate = new Date();
        let month = myDate.getMonth() + 1;
        let day = myDate.getDate();
        let age = 0;
        let resultlist = {};

        if (val.length === 18) {
            age = myDate.getFullYear() - iden.substring(6, 10);
            sex = iden.substring(16, 17);
            birth =
                iden.substring(6, 10) +
                "-" +
                iden.substring(10, 12) +
                "-" +
                iden.substring(12, 14);
            if (
                iden.substring(10, 12) < month ||
                (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
            )
            age++;
        }
        if (val.length === 15) {
            age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
            sex = iden.substring(13, 14);
            birth =
                "19" +
                iden.substring(6, 8) +
                "-" +
                iden.substring(8, 10) +
                "-" +
                iden.substring(10, 12);
            if (
                iden.substring(8, 10) < month ||
                (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
            )
                age++;
        }

        if (sex % 2 === 0) sex = "0";
        else sex = "1";
        // this.baseInfo.sex = sex;
        // this.baseInfo.age = age;
        // this.baseInfo.birthday = birth;
        // this.baseInfo.birthplace = this.area[iden.substring(0, 2)];
        resultlist['sex'] = sex;
        resultlist['age'] = age;
        resultlist['birth'] = birth;

        return resultlist;
        
    }
}